<template>
    <div class="OutBox absolute allw allh">
        <div class="ForMultiple absolute allw allh flex items-center content-between warp font18 colorfff text-center pointer border-box">
            <div v-for="(item,index) in TableNum" :key="index" @click="ChangeMultiple(index)">桌位{{item}}</div>
        </div>
    </div>
</template>

<script>
    import {mapState} from 'vuex';

    export default {
        name: 'ForMultiple',
        computed: {...mapState(['MultipleId'])},
        props: {
            'TableInfo': [Object],
            'ChangeStatusOpen': [Function],
        },
        data() {
            return {
                TableNum: [1, 2, 3, 4]
            }
        },
        methods: {
            ChangeMultiple(index) {
                let arr = this.Clone(this.MultipleId);
                if (this.MultipleId.includes(this.TableInfo.gameId)) {
                    this.Dialog(`${this.TableInfo.gameId}号已经在${this.MultipleId.indexOf(this.TableInfo.gameId) + 1}号桌`);
                    // let RootIndex = this.MultipleId.indexOf(this.TableInfo.gameId);
                    // let DelId = arr.splice(index, 1, this.TableInfo.gameId);
                    // arr.splice(RootIndex, 1, DelId[0]);
                    // setTimeout(() => {
                    //     this.OutRoom(DelId[0]);
                    // }, 50);
                    // setTimeout(() => {
                    //     this.OutRoom(this.TableInfo.gameId);
                    // }, 100);
                } else {
                    arr.splice(index, 1, this.TableInfo.gameId);
                }
                // setTimeout(() => {
                this.ChangeStatusOpen();
                this.SetStore({
                    Prop: 'MultipleId',
                    Val: arr
                });
                // }, 200);
            },
            OutRoom(ID) {
                this.SocketApi.SendSock([ID, this.cmd.ExitRoom, 0, {'gameId': ID}]);
            },
        }
    }
</script>

<style lang="less">
    .OutBox {
        top: 0;
        left: 0;

        &:hover {
            .ForMultiple {
                opacity: 1;
            }
        }

        .ForMultiple {
            top: 0;
            left: 0;
            transition: .3s;
            opacity: 0;
            background-color: rgba(39, 39, 39, .7);
            z-index: 10;
            padding: .4rem 1rem;

            > div {
                width: 1rem;
                line-height: .3rem;
                background-color: rgba(134, 134, 134, .8);

                &:hover {
                    background-color: rgba(181, 100, 38, .8);
                }
            }
        }
    }
</style>
