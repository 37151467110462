<template>
    <div :class="{'StatusOpen':!StatusOpen}" class="ChooseTable fixed allh border-box">
        <div @click="StatusOpen = !StatusOpen" :class="[LangeuageAgent!=='CH' && 'YNY']" class="absolute pointer"></div>
        <div class="flex column allh allw">
            <div class="gamePond flex warp content-between">
        <span v-for="(item,index) in Pond" :key="index" @click="GetHall(index)"
              :class="[CurrentType === index && 'currentGame',LangeuageAgent === 'CH' ? 'font20' :'font16']"
              class="text-center pointer mab10">{{Lang[item.name]}}</span>
                <span v-if="Pond.length%3 === 1 || Pond.length%3 === 2"
                      class="font20 text-center pointer mab10 visibility-hidden">占位</span>
                <span v-if="Pond.length%3 === 1" class="font20 text-center pointer mab10 visibility-hidden">占位</span>
            </div>
            <div @mousewheel="WhellControl('WhellDiv',$event)" ref="WhellDiv" class="flex1 overflow-hidden">
                <div class="allw gamelist">
                    <div v-for="(item,index) in HallInfo" :key="index" @click="ChangeTable(item,$event)"
                         class="relative">
                        <component :TableInfo="item" :is="CurrentTypeName" class="mab10"></component>
                        <ForMultiple v-if="NeedMultiple" :TableInfo="item"
                                     :ChangeStatusOpen="ChangeStatusOpen"></ForMultiple>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapState} from 'vuex';
    import Baccarat from './Baccarat';
    import LonghuLake from './LonghuLake';
    import Cattle from './Cattle';
    import SingleDouble from './SingleDouble';
    import SameSize from './SameSize';
    import ThreeCounsellors from './ThreeCounsellors';
    import PushBobbin from './PushBobbin';
    import ForMultiple from './ForMultiple';

    export default {
        name: 'ChooseTable',
        props: {
            'ArrowTable': [Boolean],
            'NeedMultiple': [Boolean],
        },
        components: {Baccarat, LonghuLake, Cattle, ThreeCounsellors, SameSize, SingleDouble, PushBobbin, ForMultiple},
        computed: {
            ...mapState(['HallInfo', 'whdef', 'SocketFn', 'DefaultConfig', 'LangeuageAgent'])
        },
        data() {
            return {
                CallBack: ['CallGetHallFn'],
                Pond: [
                    {
                        name: 'BJL', params: {  //百家乐
                            'hallType': 1,
                            'gameType': 1,
                            'url': 'Baccarat',
                            'videoSize': 'small',
                            'currentTypeName': 'Baccarat'
                        }
                    },
                    {
                        name: 'JSBJL', params: {   //极速·百家乐
                            'hallType': 2,
                            'gameType': 1,
                            'url': 'BaccaratSpeed',
                            'videoSize': 'big',
                            'currentTypeName': 'Baccarat'
                        }
                    },
                    {
                        name: 'LH', params: {   //龙虎
                            'hallType': 1,
                            'gameType': 2,
                            'url': 'LonghuLake',
                            'videoSize': 'small',
                            'currentTypeName': 'LonghuLake'
                        }
                    },
                    {
                        name: 'JSLH', params: {    //极速·龙虎
                            'hallType': 2,
                            'gameType': 2,
                            'url': 'LonghuLakeSpeed',
                            'videoSize': 'big',
                            'currentTypeName': 'LonghuLake'
                        }
                    },
                    {
                        name: 'NN', params: {   //牛牛
                            'hallType': 2,
                            'gameType': 3,
                            'url': 'Cattle',
                            'videoSize': 'big',
                            'currentTypeName': 'Cattle'
                        }
                    },
                    {
                        name: 'SG', params: {   //三公
                            'hallType': 2,
                            'gameType': 6,
                            'url': 'ThreeCounsellors',
                            'videoSize': 'big',
                            'currentTypeName': 'ThreeCounsellors'
                        }
                    },
                    {
                        name: 'DXD', params: {  //大小点
                            'hallType': 1,
                            'gameType': 5,
                            'url': 'SameSize',
                            'videoSize': 'small',
                            'currentTypeName': 'SameSize'
                        }
                    },
                    {
                        name: 'DS', params: {   //单双
                            'hallType': 1,
                            'gameType': 4,
                            'url': 'SingleDouble',
                            'videoSize': 'small',
                            'currentTypeName': 'SingleDouble'
                        }
                    },
                    {
                        name: 'TTZ', params: {  //推筒子
                            'hallType': 1,
                            'gameType': 7,
                            'url': 'PushBobbin',
                            'videoSize': 'big',
                            'currentTypeName': 'PushBobbin'
                        }
                    }
                ],
                StatusOpen: false, //是否弹出
                CurrentType: 0,
                Copywhdef: 1,
                CurrentTypeName: ''
            }
        },
        created() {
            this.$nextTick(() => {
                let GameSpeedOrOrdinary = [];
                if (this.DefaultConfig.GameSpeedOrOrdinary) {
                    this.DefaultConfig.GameSpeedOrOrdinary.forEach((item, index) => {
                        if (item) {
                            this.Pond[index].params.videoSize = item === '1' ? 'small' : 'big';
                            GameSpeedOrOrdinary.push(this.Pond[index]);
                        }
                    });
                    this.Pond = this.Clone(GameSpeedOrOrdinary);
                }
                this.CurrentType = Number(localStorage.currentType);
                this.CurrentTypeName = localStorage.HallName;
            });
        },
        watch: {
            'HallInfo': {
                handler() {
                }, deep: true
            },
            'whdef': {
                handler(n, o) {
                    if (n == 0) return;
                    this.Copywhdef = n;
                    clearTimeout(this.timer);
                    this.timer = setTimeout(() => {
                        if (this.whdef == this.Copywhdef) this.WhellControl('WhellDiv', true);
                    }, 1500);
                }, immediate: true
            },
            'ArrowTable': {
                handler(n, o) {
                    if (!n) return;
                    this.GetHall(this.CurrentType, true);
                }, deep: true, immediate: true
            }
        },
        methods: {
            GetHall(index, re) {
                if (this.CurrentType === index && !re) return;
                // if (index > 4 && index !== 7 && index !== 6) {
                //   this.dialog('暂未开放', 3);
                //   return;
                // }
                let {hallType, gameType, currentTypeName, url} = this.Pond[index].params;
                localStorage.HallName = currentTypeName;
                this.CurrentType = index;
                this.SocketApi.SendSock([this.stype.Hall, this.cmd.EnterHall, 0, {
                    hallType,
                    gameType
                }], 'CallGetHallFn');
            },
            CallGetHallFn(res) {
                let {rooms} = this.Clone(res);
                rooms = rooms || [];
                // rooms = [rooms[0]];
                let _rooms = {};
                rooms.forEach((item, index) => {
                    item.change = true; //默认处于变化阶段  是否重新渲染露珠
                    item.random = Math.random();  //防止重新获取大厅数据导致不变
                    item.roadList = item.roadList === null ? [] : item.roadList;
                    item.transForm = this.Calculation.road(item.roadList, true);
                    _rooms[item.gameId] = item;
                });
                this.SetStore({
                    Prop: 'HallInfo',
                    Val: _rooms
                });
                if (!this.NeedMultiple) this.SetStore({
                    Prop: 'MultipleId',
                    Val: res.multiRooms
                });
                this.WhellContent = 0;
                this.WhellControl('WhellDiv', true);
                this.CurrentTypeName = localStorage.HallName;
            },
            ChangeTable(item, e) {
                if (this.NeedMultiple) return;
                let {url, videoSize, currentTypeName} = this.Pond[this.CurrentType].params;
                if (e.target.tagName !== 'IMG') {
                    localStorage.currentType = this.CurrentType;
                    localStorage.type = currentTypeName;
                    localStorage.videoSize = videoSize;
                    this.StatusOpen = false;
                    setTimeout(() => {
                        this.RouterTo('GameIn', {'id': item.gameId});
                    }, 500);
                }
            },
            ChangeStatusOpen() {
                this.StatusOpen = !this.StatusOpen;
            }
        }
    }
</script>

<style lang="less">
    .ChooseTable {
        width: 4.5rem;
        right: 0;
        top: 0;
        z-index: 100;
        padding: 0.05rem .2rem;
        background-color: rgba(0, 0, 0, .6);
        transition: .3s;

        &.StatusOpen {
            transform: translate(100%, 0);
        }

        > div:first-child {
            width: 0.57rem;
            height: 1.53rem;
            background: url("../../../public/img/Common/xz_xztz_btn.png") no-repeat top center/100% 100%;
            top: 50%;
            left: 0;
            transform: translate(-100%, -50%);

            &:hover {
                background: url("../../../public/img/Common/xz_xztz_btn_hover.png") no-repeat top center/100% 100%;
            }

            &.YNY {
                background: url("../../../public/img/Common/xz_xztz_btnVNĐ.png") no-repeat top center/100% 100%;

                &:hover {
                    background: url("../../../public/img/Common/xz_xztz_btn_hoverVNĐ.png") no-repeat top center/100% 100%;
                }
            }
        }

        .gamePond {
            span {
                background-color: #3a3a3a;
                color: #c5c5c5;
                width: 1.25rem;
                line-height: .32rem;
                border-radius: 0.05rem;

                &.currentGame {
                    color: #291c03;
                    background: url("../../../public/img/GameCenter/ksyxi_btn_hover.png") no-repeat top center/cover;
                }

                &:hover {
                    color: #291c03;
                    background: url("../../../public/img/GameCenter/ksyxi_btn_hover.png") no-repeat top center/cover;
                }
            }
        }

        .gamelist {
            transition: .2s;
        }
    }
</style>
