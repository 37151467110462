<template>
    <div class="SingleDoublePond allw flex column relative oncurrent pointer border-box">
        <img v-show="$route.query.id === TableInfo.gameId" class="absolute"
             :src="'./img/Common/dqwz' + (LangeuageAgent === 'CH' ? '': LangeuageAgent) +'.png'">
        <div class="topinfo flex content-between items-center font14 zindex2">
            <span>{{Lang['DS']}}<!--单双-->{{TableInfo.gameName}}</span>
            <span>{{Lang['XH']}}<!--限红-->：{{TableInfo.limitRed}}</span>
            <!--            <span class="color333 font18 text-center visibility-hidden">-->
            <!--                {{CopyTableInfo.betTime < 0 ? 0 : CopyTableInfo.betTime}}-->
            <!--            </span>-->
        </div>
        <div ref="rightTop" class="flex1 zindex2 backgroundWhite">
            <MainRoad :RightTopH="RightTopH" :RoadList="RoadList" :IsTable="true" :DefaultCount="20"></MainRoad>
            <span :class="{'spancircle':CopyTableInfo.betTime > 0}" class="absolute font30 colorccc">
                {{CopyTableInfo.betTime <= 0 ? GetGameStatus(CopyTableInfo) : CopyTableInfo.betTime}}
            </span>
        </div>
        <div class="bottominfo zindex2 flex">
            <span v-for="(item,index) in ResultPond" :key="index"
                  :class="[index === 0 && 'colorx',index === 1 && 'colorz',index === 2 && 'visibility-hidden',LangeuageAgent === 'CH' ? 'font16' : 'font12']"
                  class="flex1">{{Lang[item]}}:<font class="colorfff">{{ResultNumber[1-index]}}</font>
                <!--                                  :class="{'colorx':index === 0,'colorz':index === 1,'visibility-hidden':index === 2}"-->

            </span>
        </div>
    </div>
</template>

<script>
    import {mapState} from 'vuex';
    import MainRoad from '../Canvas/MainRoad';

    export default {
        name: 'SingleDouble',
        props: ['TableInfo'],
        components: {MainRoad},
        computed: {
            ...mapState(['whdef', 'LangeuageAgent'])
        },
        data() {
            return {
                ResultPond: ['DSD', 'DSS', 'HJ'],//'单', '双', '和'
                RightTopH: 0,
                CopyWhdef: 1,
                Timer: null,
                RoadList: {},
                ResultNumber: [],
                CopyTableInfo: {
                    betTime: 0
                },
                FirstIn: true,
            }
        },
        created() {
            this.$nextTick(() => {
            });
        },
        watch: {
            'whdef': {
                handler(n, o) {
                    if (n === 0) return;
                    this.CopyWhdef = n;
                    clearTimeout(this.Timer);
                    this.Timer = setTimeout(() => {
                        if (this.whdef == this.CopyWhdef) {
                            let _this = this;
                            try {
                                _this.FirstIn = false;
                                _this.RightTopH = _this.$refs['rightTop'].clientHeight;
                            } catch (e) {

                            }
                        }
                    }, this.FirstIn ? 0 : 1500);
                }, immediate: true
            },
            'TableInfo': {
                handler(n, o) {
                    if (this.ToString(n) === this.ToString(o)) return;  //防止其他卓更新 当前卓也更新
                    this.CopyTableInfo = this.Clone(n);
                    if (this.ToString(n) !== this.ToString(o) && n.change === true) {
                        this.RoadList = n.transForm;
                        this.ResultNumber = this.RoadList['resultNumber'];
                    }
                }, deep: true, immediate: true
            },
            'CopyTableInfo.betTime': {  //监听倒计时
                handler(n) {
                    clearTimeout(this.Timer1);
                    if (n !== 0 && this.CopyTableInfo.gameStatus === 1) this.Timer1 = setTimeout(() => {
                        this.CopyTableInfo.betTime--;
                    }, 1000);
                    if (this.CopyTableInfo.gameStatus !== 1) {
                        this.CopyTableInfo.betTime = 0;
                    }
                }, deep: true, immediate: true
            }
        }
    }
</script>

<style lang="less">
    .zindex2 {
        position: relative;
        z-index: 2;
    }

    .SingleDoublePond {
        height: 1.6rem;
        padding: 0 0.05rem;
        background: url("../../../public/img/GameCenter/list_bg.png") no-repeat top center/100% 100%;

        > img:first-child {
            height: 1.12rem;
            right: 0px;
            top: 0px;
            z-index: 10;
            pointer-events: none;
        }

        .colorccc {
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
        }

        &.uncurrent:after {
            display: block;
            content: "";
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            border: 1px solid rgba(25, 25, 25, 1);
            box-sizing: border-box;
        }

        &.oncurrent:after {
            display: block;
            content: "";
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            border: 1px solid rgba(255, 255, 47, 1);
            box-sizing: border-box;
        }

        .topinfo {
            margin: .08rem 0;

            span:first-child {
                color: #fffefe;
                width: .9rem;
            }

            span:nth-child(2) {
                color: #af8868;
            }

            /*span:last-child {*/
            /*    width: .4rem;*/
            /*    line-height: .24rem;*/
            /*    background-image: linear-gradient(0deg,*/
            /*    #ad8a4e 0%,*/
            /*    #e6c588 100%),*/
            /*    linear-gradient(#435cc8,*/
            /*    #435cc8);*/
            /*    background-blend-mode: normal,*/
            /*    normal;*/
            /*    border-radius: 0.04rem;*/
            /*}*/
        }

        .bottominfo {
            margin: 0.065rem 0;
        }
    }
</style>
